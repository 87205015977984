import React, { useEffect, useState, useRef } from 'react';
import Bus from '../Bus';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import './index.css';

export const Flash = () => {
    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');
    // Skapa timer referens som resettas om ett nytt meddelande kommer in
    let timerRef = useRef(null);

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            clearTimeout(timerRef);
            setVisibility(true);
            setMessage(message);
            setType(type);
            timerRef = setTimeout(() => {
                setVisibility(false);
                clearTimeout(timerRef);
            }, type == "success" ? 4000 : 10000);
            return () => clearTimeout(timerRef)
        });
                

    }, []);

    useEffect(() => {
        if(document.querySelector('.close') !== null) {
            document.
            querySelector('.close').
            addEventListener('click', () => setVisibility(false));
        }
    })

    return (
        visibility && <div className={`alert alert-${type}`}>
                <CancelOutlinedIcon className="close" />
                <p>{message}</p>
            </div>
    )
}