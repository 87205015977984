import React, { Component } from 'react';

// Nya funkioner bör möjligtvis göras via react lazy istället som är en ny funktion i React 16.6.0
const asyncComponent = (importComponent) => {
    return class extends Component {
        _isMounted: boolean = false;

        state = {
            component: null
        }

        componentDidMount() {
            this._isMounted = true;
            importComponent()
                .then(cmp => {
                    this._isMounted && this.setState({ component: cmp.default });
                });
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    }
};

export { asyncComponent };