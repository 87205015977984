
// @flow
import type { IHttpService } from '../interfaces/http';
// import { AuthorizedUserBaseViewModel, AuthorizedUserViewModel } from "../../data/models/authorization";
//import { get } from "../../helpers";

class HttpService implements IHttpService {
    request: any;
    _protocol = (window.location.protocol === "https:") ? 'https://' : 'http://';
    _portHttp: number = 44369;
    _portHttps: number = 44375;
    _portDev: number = 44369;
    //_portProd: number = 80;
    _portProd: number = 44369; // Använd port 80 vid prod för att minska antalet brandväggskonfigurationer
    requestUrl: string = "";
    _requestUrlPath: string = "";

    constructor() {
        const defaults = require('superagent-defaults');
        this.request = defaults();
        this._setRequestUrl();
    }

    setRequestApiControllerPath(path: string): void {
        this._requestUrlPath = path;
        this._setRequestUrl();
    }

    appendRequestApiControllerPath(append: string): void {
        this._requestUrlPath += append;
        this._setRequestUrl();
    }

    _setRequestUrl(): void {
        //console.log("PORT TEST"); console.log(this._protocol) //console.log($npm_config_use_dmz)
        if (/localhost/.test(document.location.host))
            //this.requestUrl = `http://rundvirke-utsyning.northeurope.cloudapp.azure.com:${this._portDev}/api/v1${this._requestUrlPath}`;
            this.requestUrl = `${this._protocol}${window.location.hostname}:${this._portDev}/api/v1${this._requestUrlPath}`;
        else
            this.requestUrl = `${this._protocol}${window.location.hostname}:${this._portProd}/api/v1${this._requestUrlPath}`;
    }
}

export default HttpService;