import type { IPlanningService } from '../interfaces/planning'
import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import HttpService from '../http'

class PlanningService extends HttpService implements IPlanningService {
  _apiControllerPath: string = '/planering';

  constructor() {
    super();
    this.setRequestApiControllerPath(`${this._apiControllerPath}`)
  }
  GetAllPlans(): Promise<any> {
    return from(this.request.get(`${this.requestUrl}/getAllPlans`)).pipe(
      map((m) => m.body)
    );
  }
  GetPlan(planering_id: int): Promise<any> {
    return from(this.request.get(`${this.requestUrl}/index/${planering_id}`)).pipe(
      map((m) => m.body)
    );
  }
  GetPlanMonth(planering_id: int): Promise<any> {
    return from(this.request.get(`${this.requestUrl}/index/month/${planering_id}`)).pipe(
      map((m) => m.body)
    );
  }
  SaveNewPlan(request): Observable<any> {
    return from(
      this.request.post(`${this.requestUrl}/SaveNewPlan`).send(request)
    ).pipe(map(m => m.body));
  }
  DeletePlan(planering_id: int): Observable<any> {
    return from(
      this.request.post(`${this.requestUrl}/delete/${planering_id}`).send(planering_id)
    ).pipe(map(m => m.body));
  }
  DeletePlanRow(request): Observable<any> {
    return from(
      this.request.post(`${this.requestUrl}/delete`).send(request)
    ).pipe(map(m => m.body));
  }

  UpdatePlanRow(data): Promise<any> {
    return from(
      this.request.post(`${this.requestUrl}/update`).send(data)
    ).pipe(map(m => m.body));
  }

  AddPlanRow(data, planering_id, user): Promise<any> {
    return from(
      this.request.post(`${this.requestUrl}/add/${planering_id}`).send(data, planering_id, user)
    ).pipe(map(m => m.body));
  }
  GetPlanStatus(): Promise<any> {
    return from(this.request.get(`${this.requestUrl}/getStatus`)).pipe(
      map((m) => m.body)
    );
  }
  GetPlanType(): Promise<any> {
    return from(this.request.get(`${this.requestUrl}/getPlanType`)).pipe(
      map((m) => m.body)
    );
  }
  GetPlanning(planering_id: int): Observable<any> {
    return from(this.request
      .get(`${this.requestUrl}/index/prop/${planering_id}`))
      .pipe(map(m => m.body))
  }
  EditPlan(data, planering_id): Promise<any> {
    return from(
      this.request.post(`${this.requestUrl}/edit/${planering_id}`).send(data, planering_id)
    ).pipe(map(m => m.body));
  }
  GetPlanningPromise(planering_id: int): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request
        .get(`${this.requestUrl}/index/${planering_id}`)
        .then(r => resolve(r.body))
        .catch(e => reject(e));
    });
  }


}

export default PlanningService;